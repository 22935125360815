/*!

=========================================================
* Argon Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import DeliveryRecord from "views/pages/deliveryRecord/DeliveryRecordScreen.js";
import Car from "views/pages/car/CarScreen.js";
import Invoice from "views/pages/invoice/InvoiceScreen.js";
import Calendar from "views/pages/Calendar.js";
import Payment from "views/pages/payment/PaymentScreen";
import Customer from "views/pages/customer/CustomerScreen.js";
import Driver from "views/pages/driver/DriverScreen.js";
import User from "views/pages/user/UserScreen";
import Good from "views/pages/good/GoodScreen.js";
import Address from "views/pages/address/AddressScreen.js";
import Sample from "views/pages/SampleGoogleMaps.js";
import  AppPage  from "AppPage.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let routesFromAppPage;

// routesFromAppPage = Object.entries(AppPage).map((element) => {
//   // element[0] is the name
//   return element[1];
// })
routesFromAppPage = AppPage.RouteListObj;


export default routesFromAppPage;
